/** @jsx jsx */
import { jsx, Container, Styled } from "theme-ui"
import { useTrail } from "react-spring"
import Layout from "./layout"
import { ChildImageSharp } from "../types"
import ProjectItem from "./project-item"
import { graphql, useStaticQuery } from "gatsby"
import Card from "./Card"
import Header from "./header"
type Props = {
  projects: {
    color: string
    slug: string
    title: string
    client: string
    service: string
    cover: ChildImageSharp
  }[]
}

const Projects = ({ projects }: Props) => {
  const data = useStaticQuery(graphql`
    query {
      allProject(sort: { fields: date, order: DESC }) {
        nodes {
          ... on MdxProject {
            parent {
              ... on Mdx {
                frontmatter {
                  color
                }
              }
            }
          }
        }
      }
    }
  `)

  if (projects.length === 0) {
    return (
      <Layout>
        <Container>
          <Styled.p>
            Hi!{` `}
            <span role="img" aria-label="Wave emoji">
              👋
            </span>
            {` `}
            <br />
            Thanks for using <b>@lekoarts/gatsby-theme-emma</b>. You currently don't have any content in your{` `}
            <i>projects</i> folder - that's why this page displays a placeholder text. Head over to the{` `}
            <Styled.a href="https://github.com/LekoArts/gatsby-themes/tree/master/themes/gatsby-theme-emma">
              README
            </Styled.a>
            {` `}
            to learn how to setup them.
          </Styled.p>
          <Styled.p>
            <b>TL;DR:</b> <br />
            The starter automatically created the folder <code>content/projects</code>. Go into this folder, create a
            new folder called <code>example</code> and create an <code>index.mdx</code> file there and place an image.
            Edit the frontmatter like described in the{` `}
            <Styled.a href="https://github.com/LekoArts/gatsby-themes/tree/master/themes/gatsby-theme-emma">
              README
            </Styled.a>
            .
          </Styled.p>
        </Container>
      </Layout>
    )
  }

  return (
    <Layout>
       <Container
        sx={{
          mt: `0rem`,
          display: `grid`,
          gridTemplateColumns: [`1fr`, `repeat(auto-fill, minmax(350px, 1fr))`],
          gridColumnGap: 4,
        }}
      >
        {projects.map((project, index) => {
          const val = data.allProject.nodes[index].parent.frontmatter.color
          const shadow = `78, 81, 100`

          const px = [`64px`, `32px`, `16px`, `8px`, `4px`]
          const shadowArray = px.map(v => `rgba(${shadow}, 0.15) 0px ${v} ${v} 0px`)

          return <Card key={project.slug} item={project} overlay={shadow} shadow={shadowArray} inGrid />
        })}
        </Container>
    </Layout>
  )
}

export default Projects
